<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Editar Característica</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Característica</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Nombre de la característica"
                name="characteristic"
                :class="{ 'is-danger': vverrors.first('characteristic') }"
                v-validate="'required'"
                v-model="characteristic"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Categoría</label>
            <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('category_id') }">
              <select
                class="select"
                v-model="category_id"
                v-validate="''"
                name="category_id"
              >
                <option :value="null">Ninguna</option>
                <template v-for="(category, index) of catalogCategoriesList">
                  <option :value="category.id" :key="index">{{category.name}}</option>
                </template>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label">Estatus</label>
            <div class="select is-fullwidth">
              <select
                class="select"
                v-model="status"
                v-validate="'required|excluded:-1'"
                name="status"
              >
                <option :value="true">Activo</option>
                <option :value="false">Inactivo</option>
              </select>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
            <div class="control">
              <button class="button button-givu" @click="editCharacteristicLocal()">Actualizar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    catalogCategoriesList: {
      type: Array
    },
    editElement: {
      type: Object
    },
    editCharacteristic: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      characteristic: null,
      category_id: null,
      status: false
    }
  },
  methods: {
    async editCharacteristicLocal () {
      let data = {
        id: this.editElement.id,
        name: this.characteristic,
        categoryId: this.category_id,
        active: this.status
      }
      if (await this.$validator.validateAll()) {
        this.editCharacteristic(data)
      }
    }
  },
  beforeMount () {
    this.characteristic = this.editElement.name
    this.category_id = this.editElement.category_id
    this.status = this.editElement.active
  },
  beforeDestroy () {
    this.characteristic = null
    this.category_id = null
  }
}
</script>
